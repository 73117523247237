<template>
<div v-if="parentProfile !== null">

  <b-modal id="modal-delete" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Delete this questionnaire?
    </template>
    <div class="d-block">
      <p>If you delete this questionnaire, the parent won't be able to complete it.</p>
      <p>You will receive your credits back.</p>
      <label for="verificationCode">Type in "DELETE" to activate the delete button</label>
      <input :value="deleteBlocker.toUpperCase()" @input="deleteBlocker = $event.target.value.toUpperCase()" type="text" placeholder="" class="form-control">
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-delete')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1" v-on:click="deleteQuestionnaire()" :disabled="deleteBlocker !== 'DELETE'">Yes, delete</button>
      </div>
    </div>
  </b-modal>

  <div class="container-fluid wrapper-medium mt-3">

    <div class="row mb-3" v-if="parentProfile !== null && report !== null">
      <div class="col-12">
        <b-link class="btn btn-link btn-sm pl-0 mb-2" :to="'/profile/' + id "><span class="icon icon-left-open mr-2 pl-0"></span>Parent & Child Profile</b-link>
        <h2 class="anim-load1">TAYC-R Questionnaire results</h2>
      </div>
    </div>

    <div class="row py-2" v-bind:class="{'sticky-top': report.submittedOn}" v-if="parentProfile !== null && report !== null">
      <div class="col-6">
        <h4 class="mt-2">{{parentProfile.parentFirstName}} {{parentProfile.parentLastName}} & {{parentProfile.childFirstName}}</h4>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>Created: </strong>{{ $date(report.sentOn).format('DD MMM YYYY') }}<br>
          <strong>Completed: </strong> 
          <span v-if="report.submittedOn">{{ $date(report.submittedOn).format('DD MMM YYYY') }}</span>
          <span v-else>Awaiting completion</span>
        </p>
      </div>
    </div>
  
    <div class="row mb-5" v-if="report !== null">
      <div class="col-12 col-md-8" v-if="report.submittedOn === null">
        <div class="card h-100">
          <div class="card-body">
            <h5>Link to questionnaire</h5>
            <p>We've sent the link to this questionnaire to the parent's email address already. If you'd like to share it with the parent again, please copy the link below.</p>
            <div class="input-group">
              <input v-model="questionnaireLink" type="text" class="form-control" readonly>
              <button class="btn btn-warning" v-on:click="copyLink()">Copy link</button>
            </div>
          </div> 
        </div>
      </div>
      <div class="col-12 col-md-4 mt-3 mt-md-0" v-if="report.submittedOn === null">
        <div class="card h-100">
          <div class="card-body">
            <p class="lead">This questionnaire has not been completed yet.</p>
            <b-link class="btn btn-danger btn-sm" v-on:click="$bvModal.show('modal-delete')">Delete this questionnaire</b-link>
          </div> 
        </div>
      </div>

      <div class="col-12 mb-3" v-if="report.submittedOn !== null">
        <div class="card">
          <div class="card-body">
            <h4>Individual answers</h4>
            <ol class="list-standard list-answers">
              <li class="" v-for="item in questionsAnswers" v-bind:key="item.id">
                <div class="d-flex justify-content-between">
                  <span>{{item.text}}</span>
                  <h5>
                    <span class="badge badge-pill badge-primary badge-pink h-number">{{item.score}} / 5</span>
                  </h5>
                </div>
              </li>
            </ol>
          </div> 
        </div>
      </div>


      <div class="col-12" v-if="report.submittedOn !== null">
        <SurveyResults :report="reportData" />
      </div>

     
      <div class="col-12 mt-3 text-center">
        <div class="card">
          <div class="card-body">
            <h2 class="mb-3">Email these TAYC-R results</h2>
          <p class="lead">
              Would you like to save or share your TAYC-R results with a Local Authority, Social worker, family member, or yourself?
          </p>
          <p class="lead">
              Enter your email address and click share to send these results now.
          </p>
          <ValidationObserver ref="form">
            <b-form @submit.prevent class="mb-3">
              <div class="row">
                <div class="col-8">
                  <div class="form-group" ref="email">
                    <label class="float-left" for="emailInput">Email address</label>
                      <ValidationProvider v-slot="{classes, errors}" rules="required" :name="form.email">
                        <input v-model="form.email" type="text" name="emailInput" class="form-control" v-bind:class="classes">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                  </div>
                </div>
              
                <div class="col-2" style="padding-top:15px;">
                  <button type="submit" class="btn btn-lg btn-warning" v-on:click="submit()">Share</button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>

          </div>
        </div>
         
      </div>


    </div>

  </div>

  <Footer />
</div>

</template>

<script>
import accountDetails from '@/mixins/accountDetails'
import SurveyResults from '@/components/modules/SurveyResults'
import {surveyFields} from '@/surveyFields'

export default {
  name: 'ProfileReport',
  props: ['id', 'reportId'],
  components: { SurveyResults },
  mixins: [accountDetails],
  data () {
    return {
      deleteBlocker: '',
      taycrData: [],
      parentProfile: null,
      report: null,
      reportData: null,
      taycrQuestions: surveyFields.taycrQuestions,
      form: {
        email: null
      }
    }
  },
  computed: {
    questionnaireLink() {
      return `${window.location.origin}/taycr?id=${this.reportId}&profile=${this.id}`
    },
    questionsAnswers() {
      var arr = {}
      arr = this.taycrQuestions.map((question) => {
        var scoreRef = question.id.toUpperCase()
        return {
          score: this.reportData?.answers[scoreRef],
          ...question
        }
      })
      return arr
    },
    computed: {
    formComp () {
      return this.$refs.form;
    }
  },
  },
  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    await Promise.all([this.accountPersonalDetailsGet(), this.getParentProfile()]);
    this.$store.state.loadingProgress = false
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.questionnaireLink)
      this.alertMsgShow('Link copied to clipboard', true)
    },
      /*
    changeReportParentVisibility(status){
      this.$http({
        method: 'post', 
        url: this.$api.QuestionnaireVisibility,
        params: {
          questionnaireId: this.reportId,
          parentProfileId: this.parentProfile.id, 
          status: status,
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          if(status == true){
            this.alertMsgShow('Questionnaire results can now be viewed by the parent.', true)
          } else {
            this.alertMsgShow('Questionnaire results can no longer be viewed by the parent.', true)
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong. Please try again', false)
        })
    },
    */
    deleteQuestionnaire() {
      this.$http({
        method: 'post', 
        url: this.$api.QuestionnaireCancel,
        params: {
          questionnaireId: this.reportId
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow('Questionnaire deleted', true)
          this.$router.push(`/profile/${this.id}`)
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong. Please try again', false)
        })
    },

      getParentProfile() {

          const isAdmin = this.$store.state.user.roles.includes('Admin');
const isSuperOrgAdmin = this.$store.state.user.roles.includes('OrgSuperAdmin');

          const apiCall = (isAdmin || isSuperOrgAdmin) ? `${this.$api.AdminProfileGet}/${this.id}` : this.$api.QuestionnaireProfilesGet;

      this.$http({
        method: 'get', 
          url: apiCall,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
          .then((response) => {

              const profiles = Array.isArray(response.data) ? response.data : [response.data];

              if (profiles.length > 1) {
                  this.parentProfile = response.data.find(profile => profile.id === this.id)
              }
              else {
                  this.parentProfile = profiles[0];
              }

          this.getQuestionnaires()
          if (!this.parentProfile) {
            this.alertMsgShow("Profile doesn't exist", false)
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
          this.$router.push('/dashboard')
        })
    },

      getQuestionnaires() {
          const isAdmin = this.$store.state.user.roles.includes('Admin');
          const isSuperOrgAdmin = this.$store.state.user.roles.includes('OrgSuperAdmin');
          const apiCall = (isAdmin || isSuperOrgAdmin) ? `${this.$api.AdminQuestionnaireAllGet}/${this.parentProfile.id}` : this.$api.QuestionnaireAllGet;

      this.$http({
        method: 'get', 
          url: apiCall,
        params: {
          profileId: this.parentProfile.id
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          this.report = response.data.find(item => item.questionnaireId === this.reportId)
          if (this.report === null || this.report === undefined) {
            this.alertMsgShow("Can't read questionnaire results", false)
            this.$router.push(`/profile/${this.id}`)
          } else {
            this.report ? this.getSummary() : null
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
    },

    getSummary () {
      this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireSummaryGet,
        params: {
          profileId: this.parentProfile.id,
          questionnaireId: this.reportId
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          this.reportData = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
    },

    async submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          var data = {
            email: this.form.email,
          }

          this.$http({
            method: 'post', 
            url: this.$api.QuestionnaireShare,
            params: {
              id: this.reportId,
              profileId: this.parentProfile.id
            },
            data: data
          })
            .then((response) => {
              console.log(response)
              this.alertMsgShow('TAYCR has been successfully shared via email to ' + this.form.email, true);
            })
            .catch((error) => {
              console.error('Error', error.response.data)
              if (error.response.status == 500) {
                this.alertMsgShow('This questionnaire has been deleted. Please contact your therapist.', false);
                this.$router.push('/')
              } else {
                this.alertMsgShow('Something went wrong. Please contact your therapist.', false);
              }
            })
            .finally(() => {
              this.$store.state.loadingProgress = false
            })        
        }
      });
    },

  }
}
</script>

<style lang="scss">
  .sticky-top {
    background-color: #f0f3f7 ;
  }
</style>