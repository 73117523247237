<template>
  <div>
    <div class="survey-content" ref="surveyContent" v-html="content"
      v-bind:class="{'expanded': psuContentExpanded}"></div>
    <button v-if="psuContentOverflow" class="btn btn-warning" v-on:click="psuContentExpanded = !psuContentExpanded">
      <span v-if="!psuContentExpanded">Read more <i class="icon icon-down-open ml-2"></i></span>
      <span v-else>Collapse <i class="icon icon-up-open ml-2"></i></span></button>
  </div>
</template>

<script>
  export default {
    name: 'SurveyContent',
    props: ['itemName', 'content'],
    data: function () {
      return {
        psuContentExpanded: false,
        psuContentOverflow: false
      }
    },
    computed: {
      contentComp () {
        return this.content
      }
    },
    mounted() {
      this.psuContentOverflow = this.checkOverflow(this.$refs.surveyContent);
    },
    methods: {
      checkOverflow: function(el) {
        console.log(el)
        var curOverflow = el.style.overflow;
        if ( !curOverflow || curOverflow === "visible" )
            el.style.overflow = "hidden";
        var isOverflowing = el.clientWidth < el.scrollWidth 
            || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
      },
    }
  }
</script>