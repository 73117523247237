<template>
<div v-if="reportData !== null">
  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4>Parent Skills and Understanding (PSU): <span class="badge badge-pill badge-primary badge-pink h-number">{{reportData.psuSummary.score}}</span></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 text-center text-md-left">
          <vue-gauge :refid="'psuGauge'" :options="gaugePsu"></vue-gauge>
        </div>
        <div class="col-12 col-md-8 col-lg-8 pr-lg-1">
          <h6 class="mt-md-3 mb-3">Score interpretation</h6>
          <SurveyContent :itemName="'psuContent'" :content="reportData.psuSummary.guidance" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4>Parent-Child Relationship (PCR): <span
              class="badge badge-pill badge-primary badge-pink h-number">{{reportData.pcrSummary.score}}</span></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 text-center text-md-left">
          <vue-gauge :refid="'pcrGauge'" :options="gaugePcr"></vue-gauge>
        </div>
        <div class="col-12 col-md-8 col-lg-8 pr-lg-1">
          <h6 class="mt-md-3 mb-3">Score interpretation</h6>
          <SurveyContent :itemName="'pcrContent'" :content="reportData.pcrSummary.guidance" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4>Child Responsiveness to Care (CRC): <span
              class="badge badge-pill badge-primary badge-pink h-number">{{reportData.crcSummary.score}}</span></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 text-center text-md-left">
          <vue-gauge :refid="'crcGauge'" :options="gaugeCrc"></vue-gauge>
        </div>
        <div class="col-12 col-md-8 col-lg-8 pr-lg-1">
          <h6 class="mt-md-3 mb-3">Score interpretation</h6>
          <SurveyContent :itemName="'crcContent'" :content="reportData.crcSummary.guidance" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4>Placement Stability (ST): <span class="badge badge-pill badge-primary badge-pink h-number">{{reportData.stSummary.score}}</span></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 text-center text-md-left">
          <vue-gauge :refid="'stGauge'" :options="gaugeSt"></vue-gauge>
        </div>
        <div class="col-12 col-md-8 col-lg-8 pr-lg-1">
          <h6 class="mt-md-3 mb-3">Score interpretation</h6>
          <SurveyContent :itemName="'stContent'" :content="reportData.stSummary.guidance" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h3>Total score: <span
              class="badge badge-pill badge-primary badge-pink h-number">{{reportData.totalSummary.score}}</span>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 text-center text-md-left">
          <vue-gauge :refid="'totalGauge'" :options="gaugeTotal"></vue-gauge>
        </div>
        <div class="col-12 col-md-8 col-lg-8 pr-lg-1">
          <h6 class="mt-md-3 mb-3">Score interpretation</h6>
          <SurveyContent :itemName="'totalContent'" :content="reportData.totalSummary.guidance" />
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import VueGauge from 'vue-gauge';
import SurveyContent from "@/components/modules/SurveyContent.vue"
import {surveyFields} from '@/surveyFields'

export default {
  name: 'SurveyResults',
  props: ['report'],
  components: {
    VueGauge,
    SurveyContent
  },
  data() {
    return {
      reportData: null,
      questionsAnswers: [],
      taycrQuestions: surveyFields.taycrQuestions,
      gaugeOptions: {
        needleValue: 0,
        arcDelimiters: [33, 66],
        rangeLabel: ['0', '100'],
        arcColors: ['rgb(255,84,84)', 'rgb(239,214,19)', 'rgb(61,204,91)'],
        needleColor: '#212529',
        needleUpdateSpeed: 2000
      },
      gaugePsu: null,
      gaugePcr: null,
      gaugeCrc: null,
      gaugeSt: null,
      gaugeTotal: null
    }
  },
  computed: {
  },
  created() {
    if (this.report !== null) {
      this.answersDataRender()
    }
  },
  methods: {
    answersDataRender() {
      this.reportData = this.report
      // adjust chart options
      this.gaugePsu = JSON.parse(JSON.stringify(this.gaugeOptions));
      this.gaugePcr = JSON.parse(JSON.stringify(this.gaugeOptions));
      this.gaugeCrc = JSON.parse(JSON.stringify(this.gaugeOptions));
      this.gaugeSt = JSON.parse(JSON.stringify(this.gaugeOptions));
      this.gaugeTotal = JSON.parse(JSON.stringify(this.gaugeOptions));

      var psuData = this.reportData.psuSummary
      this.gaugePsu.rangeLabel = [psuData.levelRanges.low.max.toString(), '0']
      this.gaugePsu.needleValue = psuData.score / psuData.levelRanges.low.max * 100
      this.gaugePsu.arcDelimiters = [psuData.levelRanges.high.max / psuData.levelRanges.low.max * 100, psuData.levelRanges.low.min / psuData.levelRanges.low.max * 100
      ]

      var pcrData = this.reportData.pcrSummary
      this.gaugePcr.rangeLabel = [pcrData.levelRanges.low.max.toString(), '0']
      this.gaugePcr.needleValue = pcrData.score / pcrData.levelRanges.low.max * 100
      this.gaugePcr.arcDelimiters = [pcrData.levelRanges.high.max / pcrData.levelRanges.low.max * 100, pcrData.levelRanges.low.min / pcrData.levelRanges.low.max * 100
      ]

      var crcData = this.reportData.crcSummary
      this.gaugeCrc.rangeLabel = [crcData.levelRanges.low.max.toString(), '0']
      this.gaugeCrc.needleValue = crcData.score / crcData.levelRanges.low.max * 100
      this.gaugeCrc.arcDelimiters = [crcData.levelRanges.high.max / crcData.levelRanges.low.max * 100, crcData.levelRanges.low.min / crcData.levelRanges.low.max * 100
      ]

      var stData = this.reportData.stSummary
      var stScoreAdjusted = stData.score
      this.gaugeSt.rangeLabel = [stData.levelRanges.low.max.toString(), '0']
      if (stScoreAdjusted === 3) {
        stScoreAdjusted = 2.8
      } else if (stScoreAdjusted === 4) {
        stScoreAdjusted = 3.8
      } else if (stScoreAdjusted === 5) {
        stScoreAdjusted = 4.9
      }
      this.gaugeSt.needleValue = stScoreAdjusted / stData.levelRanges.low.max * 100
      this.gaugeSt.arcDelimiters = [(stData.levelRanges.high.max) / stData.levelRanges.low.max * 100, (stData.levelRanges.low.min - 0.5) / stData.levelRanges.low.max * 100]

      var totalData = this.reportData.totalSummary
      this.gaugeTotal.rangeLabel = [totalData.levelRanges.low.max.toString(), '0']
      this.gaugeTotal.needleValue = totalData.score / totalData.levelRanges.low.max * 100
      this.gaugeTotal.arcDelimiters = [totalData.levelRanges.high.max / totalData.levelRanges.low.max * 100,
        totalData.levelRanges.low.min / totalData.levelRanges.low.max * 100
      ]

      // questions and answers
      this.taycrQuestions.forEach((item, index) => {
        var obj = {
          text: item.text,
          id: item.id,
          answer: this.reportData.answers['Q' + (index + 1)]
        }
        this.questionsAnswers.push(obj)
      });
    },
  },
  watch: {
    report() {
      this.answersDataRender()
    }
  }
}
</script>